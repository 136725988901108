import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"
import { MoreInformationButtonLink } from "../../common/MoreInformationButtonLink"

import OtsutomeLogo from "../../../../images/service/cloud_otsutome_logo.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
  width: 100%;
  max-width: 960px;
  padding: 96px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 48px;
    padding: 80px 20px;
  }
`

const Logo = styled.img`
  width: 100%;
  max-width: 320px;
  margin: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 240px;
  }
`

const Image = styled(Img)`
  width: 100%;
  max-width: 640px;
  margin: auto;
`

const Descriptions = styled.div`
  display: flex;
  flex-direction: column;
`
const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #222222;
  word-break: break-word;
`
const Functions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  justify-content: center;
  gap: 24px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    grid-template-columns: repeat(auto-fit, 144px);
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const Function = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 180px;
  aspect-ratio: 1;
  background: #F6BE70;
  border-radius: 50%;
`
const FunctionSub = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
  }
`
const FunctionTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

type Props = {
  title: string
  caption: string
  img: any
  descriptions: Array<any>
  functions: Array<any>
  url
}
export const Otsutome: React.VFC<Props> = ({title, caption, img, descriptions, functions, url}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        <Content>
          <Logo src={OtsutomeLogo} alt={title} />
          <Image fluid={img} alt={descriptions.join("")} />
          <Descriptions>
            {
              descriptions.map((description, i) => {
                return (
                  <Description key={i}>{description}</Description>
                )
              })
            }
          </Descriptions>
          <Functions>
            {
              functions.map((f, i) => {
                return (
                  <Function key={i}>
                    <FunctionSub>{f.sub}</FunctionSub>
                    <FunctionTitle>{f.title}</FunctionTitle>
                  </Function>
                )
              })
            }
          </Functions>
          <MoreInformationButtonLink url={url} />
        </Content>
      </Contents>
    </Wrapper>
  )
}
