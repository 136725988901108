import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"
import { MoreInformationButtonLink } from "../../common/MoreInformationButtonLink"

import NextwallLogo from "../../../../images/service/cloud_nextwall_logo.svg"

import FunctionLogo1 from "../../../../images/service/cloud_nextwall_attendance.svg"
import FunctionLogo2 from "../../../../images/service/cloud_nextwall_coin.svg"
import FunctionLogo3 from "../../../../images/service/cloud_nextwall_report.svg"
import FunctionLogo4 from "../../../../images/service/cloud_nextwall_team.svg"
import FunctionLogo5 from "../../../../images/service/cloud_nextwall_idea.svg"
import FunctionLogo6 from "../../../../images/service/cloud_nextwall_calendar.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
  width: 100%;
  max-width: 960px;
  padding: 96px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 48px;
    padding: 80px 20px;
  }
`

const Logo = styled.img`
  width: 100%;
  max-width: 440px;
  margin: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 240px;
  }
`
const Head = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const Catchcopy = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`
const Descriptions = styled.div`
  display: flex;
  flex-direction: column;
`
const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  color: #222222;
  word-break: break-word;
`
const Functions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    grid-template-columns: repeat(auto-fit, 192px);
    gap: 30px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
`
const Function = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  aspect-ratio: 1;
  background: #EFEFEF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px;
  }
`
const FunctionVerticalImage = styled.img`
  height: 80px;
  margin: 0;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    height: 64px;
  }
`

const FunctionTitle = styled.h4`
  margin: 0;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #222222;

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
  }
`

type Props = {
  title: string
  caption: string
  catchcopy: string
  descriptions: Array<any>
  functions: Array<any>
  url
}
export const Nextwall: React.VFC<Props> = ({title, caption, catchcopy, descriptions, functions, url}) => {
  const icons = [
    FunctionLogo1,
    FunctionLogo2,
    FunctionLogo3,
    FunctionLogo4,
    FunctionLogo5,
    FunctionLogo6,
  ];
  const isVerticals = [
    false,
    true,
    true,
    true,
    true,
    false,
  ];

  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        <Content>
          <Logo src={NextwallLogo} alt={title} />
          <Head>
            <Catchcopy>{catchcopy}</Catchcopy>
            <Descriptions>
              {
                descriptions.map((description, i) => {
                  return (
                    <Description key={i}>{description}</Description>
                  )
                })
              }
            </Descriptions>
          </Head>
          <Functions>
            {
              functions.map((f, i) => {
                return (
                  <Function key={i}>
                    <FunctionVerticalImage src={icons[i]} alt={f} />
                    <FunctionTitle dangerouslySetInnerHTML={{__html: f}} />
                  </Function>
                )
              })
            }
          </Functions>
          <MoreInformationButtonLink url={url} title={"お問い合わせはこちら"} />
        </Content>
      </Contents>
    </Wrapper>
  )
}
