import React from "react"
import { ButtonLink } from "../common/ButtonLink";

type Props = {
  url: string
  title?: string
}
export const MoreInformationButtonLink: React.VFC<Props> = ({url, title="詳しくはこちら"}) => {
  return (
    <ButtonLink title={title} url={url} />
  )
}
