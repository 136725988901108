import React from "react"
import styled from "styled-components"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { ServiceContainer } from "../common/ServiceContainer";
import { SectionTitle } from "../../common/SectionTitle";

import { Otsutome } from "./common/Otsutome";
import { Nextwall } from "./common/Nextwall";
import { ServiceContentContainer } from "../common/ServiceContentContainer";
import { RESPONSIVE_STYLES } from "../../../AppConst";
import { MainVisual } from "../common/MainVisual";
import { Message } from "../common/Message";
import { GrayContainer } from "../common/GrayContainer";
import { WhiteContainer } from "../common/WhiteContainer";
import { PickUpArticleList } from "../common/PickUpArticleList";
import { Faq } from "../common/Faq";
import { ogpUrl } from "../../../utlis/ImageUtil"

/**
 * Component
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  icon: any
  imgMain: any
  imgOtsutome: any
  cloud: any
}

/**
 * Component
 */
export const ServiceCloud: React.VFC<Props> = (props) => {

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title={props.cloud.ogp.title}
        description={props.cloud.ogp.description}
        img={ogpUrl(process.env.NODE_ENV, props.imgMain)}
      />
      <ServiceContainer paddingBottom={0}>
        <SectionTitle title={props.cloud.title.en} subTitle={props.cloud.title.ja} icon={props.icon} />
        <ServiceContentContainer>
          <MainVisual img={props.imgMain} alt={props.cloud.title.ja} />
          <Message title={props.cloud.message.title} description={props.cloud.message.description} />
          <GrayContainer>
            <Otsutome
              title={props.cloud.otsutome.title.ja}
              caption={props.cloud.otsutome.title.en}
              img={props.imgOtsutome}
              descriptions={props.cloud.otsutome.descriptions}
              functions={props.cloud.otsutome.functions}
              url={props.cloud.otsutome.url}
            />
            <Nextwall
              title={props.cloud.nextwall.title.ja}
              caption={props.cloud.nextwall.title.en}
              catchcopy={props.cloud.nextwall.catchcopy}
              descriptions={props.cloud.nextwall.descriptions}
              functions={props.cloud.nextwall.functions}
              url={props.cloud.nextwall.url}
            />
          </GrayContainer>
          {
            // TODO: 後日公開
          // <WhiteContainer>
          //   <PickUpArticleList articles={props.articles} url={props.cloud.pickup.url} />
          // </WhiteContainer>
          }
          {
            // FAQはサービスのページに記載
          //<GrayContainer>
          //  <Faq
          //    title={props.cloud.faq.title.ja}
          //    contents={props.cloud.faq.contents}
          //  />
          //</GrayContainer>
          }
        </ServiceContentContainer>
      </ServiceContainer>
    </Layout>
  )
}
