import React from "react"
import { graphql } from "gatsby"
import { ServiceCloud } from "../../../components/service/cloud"
import Icon from "../../../images/service/icon_service_cloud.svg"

export default function ServiceCloudPage({ data }) {
  const {
    imgMain,
    imgOtsutome,
    site: { siteMetadata: { services } }
  } = data;

  const breadcrumbsData: { text: string; path: string }[] = [
    { text: services.top.title.en, path: services.top.path },
    { text: services.cloud.title.ja, path: services.cloud.path },
  ]

  return (
    <ServiceCloud
      breadcrumbsData={breadcrumbsData}
      icon={Icon}
      imgMain={imgMain.childImageSharp.fluid}
      imgOtsutome={imgOtsutome.childImageSharp.fluid}
      cloud={services.cloud}
    />
  )
}

export const query = graphql`
  query ServiceCloudQuery {
    site {
      siteMetadata {
        services {
          top {
            path
            title {
              ja
              en
            }
          }
          cloud {
            path
            ogp {
              title
              description
            }
            title {
              ja
              en
            }
            description
            message {
              title
              description
            }
            otsutome {
              title {
                ja
                en
              }
              descriptions
              functions {
                sub
                title
              }
              url
            }
            nextwall {
              title {
                ja
                en
              }
              catchcopy
              descriptions
              functions
              url
            }
            pickup {
              url
            }
            faq {
              title {
                ja
              }
              contents {
                question
                answer
              }
            }
          }
        }
      }
    }
    imgMain: file(relativePath: { eq: "images/service/cloud_thumbnail.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgOtsutome: file(relativePath: { eq: "images/service/cloud_otsutome.png"}) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
